import React, { useEffect, useState } from "react";
// import img1 from "../../../assets/img/pre-sign/video_1.jpg";
// import img2 from "../../../assets/img/pre-sign/video_2.jpg";
import img1 from "../../../assets/img/pre-sign/video_1.webp";
import img2 from "../../../assets/img/pre-sign/video_2.webp";

import LoadImage from "../../../components/core/LazyImage";
import DelayLink from "../../../components/core/DelayLink";
import CustomButton from "../../../components/core/ButtonDefault";

const videos = [
  {
    img: img2,
    url: "https://www.youtube.com/embed/2zbDmjJ7sAM",
    title: "Watch the <span> funtico platform </span> trailer!",
  },
  {
    img: img1,
    url: "https://www.youtube.com/embed/v19l3BXsE8Q",
    title: "Watch <span> The Heroes of the citadel </span> trailer!",
  },
];
const ESCAPE_KEYS = ["27", "Escape"];

export default function Trailers({ state }) {
  const [isOpen, setIsOpen] = useState(false);
  const [videoUrl, setVideoUrl] = useState(null);
  const version = state.getState().app_page;

  const keyHandler = ({ key }) => {
    ESCAPE_KEYS.includes(String(key)) && setIsOpen(false);
  };

  const closeOnClick = ({ target }) => {
    if (target.dataset.close) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    videoUrl && setIsOpen(true);
    return () => {};
  }, [videoUrl]);

  useEffect(() => {
    isOpen && document.addEventListener("keydown", keyHandler, false);
    isOpen && document.addEventListener("click", closeOnClick, false);

    return () => {
      document.removeEventListener("keydown", keyHandler, false);
      document.removeEventListener("click", closeOnClick, false);
      isOpen && setTimeout(() => setVideoUrl(null), 300);
    };
  }, [isOpen]);

  return (
    <>
      <section className="trailers">
        <div className="section_content df fd-c ai-c pd-lr-lg">
          <h2>Check out Our Latest Trailers!</h2>

          <div className="videos df">
            {videos.map((video, i) => (
              <div
                className="video"
                key={i}
                onClick={(e) => setVideoUrl(video)}
              >
                <div className="icon cover df br-16">
                  <LoadImage src={video.img} />
                  <div className="play df">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 80 80">
                      <path d="M70.5473 10.9348C56.2815 -4.25061 31.0339 -3.5275 14.1756 12.5283C-2.68274 28.5708 -4.82331 53.8932 9.46905 69.0652C23.7348 84.2506 48.9825 83.5275 65.8408 67.4717C82.6992 51.4426 84.7998 26.1202 70.5473 10.9348ZM60.9083 40.9307C60.8817 41.3994 60.7089 41.8547 60.443 42.2698C60.1638 42.6581 59.8314 42.9929 59.3927 43.1938L28.8004 58.9818C28.335 59.2362 27.8032 59.3165 27.2847 59.2898C26.7529 59.2362 26.2609 59.0353 25.8222 58.7006C25.4101 58.3658 25.0776 57.9239 24.9048 57.415C24.7453 56.9195 24.6788 56.3571 24.8251 55.8617L32.4432 22.6921C32.5496 22.2502 32.749 21.8351 33.0813 21.4601C33.3871 21.0986 33.7727 20.8442 34.2115 20.6835C34.6502 20.5228 35.1289 20.4826 35.5676 20.5495C36.0329 20.6031 36.4584 20.804 36.8439 21.0852L59.8314 38.4533C60.2169 38.7345 60.4962 39.1229 60.6956 39.538C60.8817 39.9933 60.9615 40.462 60.9083 40.9307Z" />
                    </svg>
                  </div>
                </div>
                <p
                  className="fixel_22"
                  dangerouslySetInnerHTML={{ __html: video.title }}
                ></p>
              </div>
            ))}
          </div>
          <a
            href="http://litepaper.funtico.com"
            title="Discover Funtico’s Litepaper!"
            className="vision_link"
            target="_blank"
          >
            <CustomButton
              classname="pr"
              def={40}
              title="Discover Funtico’s Litepaper!"
            ></CustomButton>
          </a>
        </div>
      </section>

      {videoUrl?.url ? (
        <div className={`pop_up ${isOpen ? "active" : ""}`} data-close="true">
          <div className="overflow df jc-c ai-st" data-close="true">
            <div className={`content df fd-c data trailer`}>
              <iframe
                src={videoUrl.url}
                title="Watch the funtico platform trailer!"
                allowFullScreen
              ></iframe>
              <p
                className="fixel_16_sbl mr-t-24"
                dangerouslySetInnerHTML={{ __html: videoUrl.title }}
              ></p>
              <button
                className="df close close_icon"
                data-close="true"
              ></button>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}
