let store = {
  _state: {
    basePath:
      window.location.origin === "https://tests.bambus.com.ua"
        ? "/funtico/"
        : "/",
    opened_popup: { open: false },
    isMobile: window.innerWidth < 1025,
    isPageLoading: true,
    documentTitle: "Funtico",
    pageTitle: null,
    country: "",
    app_page: "sign",  // platform | sign | preview
    avatars: [],
  },

  _callSubscriber() {},

  getState() {
    return this._state;
  },

  subscribe(observer) {
    this._callSubscriber = observer;
  },

  setPopUp(type, arr) {
    this._state.opened_popup = {
      // ...this._state.opened_popup,
      ...arr,
      open: type,
    };
    this._callSubscriber(this.getState());
  },
  setDeviceType(width) {
    this._state.isMobile = width < 1025;
    this._callSubscriber(this.getState());
  },
  setAvatars(avatars) {
    this._state.avatars = avatars;
    this._callSubscriber(this.getState());
  },
  setCountry(cntr) {
    this._state.country = cntr;
    this._callSubscriber(this.getState());
  },
  setLoading(active) {
    this._state.isPageLoading = active;
    this._callSubscriber(this.getState());
  },
  setPageTitle(title) {
    this._state.pageTitle = title;
    this._callSubscriber(this.getState());
  },
};

export default store;
