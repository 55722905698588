import SwiperCore from "swiper";
import { Mousewheel, Scrollbar } from "swiper/modules";

import { Swiper, SwiperSlide } from "swiper/react";
SwiperCore.use([Mousewheel, Scrollbar]);

import NFT_1 from "../../../assets/img/pre-sign/NFT_1.png";
import NFT_2 from "../../../assets/img/pre-sign/NFT_2.png";
import NFT_3 from "../../../assets/img/pre-sign/NFT_3.png";
import NFT_4 from "../../../assets/img/pre-sign/NFT_4.png";
import NFT_5 from "../../../assets/img/pre-sign/NFT_5.png";

import LoadImage from "../../../components/core/LazyImage";

const array = [
  {
    name: "Vastron",
    image: NFT_1,
    rar: "Epic (Limited)",
    coll: "Pioneer",
    cat: "Equippable",
  },
  {
    name: "Merilda",
    image: NFT_2,
    rar: "Epic (Limited)",
    coll: "Pioneer",
    cat: "Equippable",
  },
  {
    name: "Ton",
    image: NFT_3,
    rar: "Epic (Limited)",
    coll: "Pioneer",
    cat: "Playable",
  },
  {
    name: "Shogun",
    image: NFT_4,
    rar: "Epic (Limited)",
    coll: "Pioneer",
    cat: "Playable",
  },
  {
    name: "Magic Shark",
    image: NFT_5,
    rar: "Epic (Limited)",
    coll: "Pioneer",
    cat: "Equippable",
  },
];

export default function Rewards() {
  return (
    <>
      <section className="rewards">
        <div className="section_content df fd-c ai-c">
          <div className="text_content">
            <h2>More About The Pioneer Rewards</h2>
          </div>
          <div className="slider">
            <Swiper
              slidesPerView={"auto"}
              // grabCursor={true}
              spaceBetween={"20"}
              scrollbar={true}
              mousewheel={{ forceToAxis: true }}
            >
              {array.map((m, i) => (
                <SwiperSlide key={i} className="slide market_slide df fd-c">
                  <div className="icon df">
                    <LoadImage src={m.image} />
                  </div>

                  <p className="name fixel_22">{m.name}</p>
                  <div className="data df fd-c">
                    <div className="row df jc-sb">
                      <p className="l fixel_14">Rarity</p>
                      <p className="fixel_14 lim">{m.rar}</p>
                    </div>
                    <div className="row df jc-sb">
                      <p className="l fixel_14">Category</p>
                      <p className="fixel_14">{m.cat}</p>
                    </div>
                    <div className="row df jc-sb">
                      <p className="l fixel_14">Collection</p>
                      <p className="fixel_14">{m.coll}</p>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </section>
    </>
  );
}
