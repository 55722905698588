import React, { Suspense, lazy, useEffect, useRef, useState } from "react";
import gsap, { ScrollToPlugin, ScrollTrigger } from "gsap/all";
import CustomButton from "../../../components/core/ButtonDefault";
// import LoadImage from "../../../components/core/LazyImage";
// import scratch from "../../../assets/img/pre-sign/Banner.png";
import scratch from "../../../assets/img/pre-sign/Banner.webp";
// import scratch_bg from "../../../assets/img/temp/cards_slide_bg.jpg";
import RegistrationLabels from "../../../components/pop-ups/RegistrationLabels";
import video_start from "../../../assets/img/pre-sign/intro_start.mp4";
import video_loop from "../../../assets/img/pre-sign/intro_loop.mp4";
// import LoadImage from "../../../components/core/LazyImage";

gsap.registerPlugin([ScrollTrigger, ScrollToPlugin]);
const errTXT = "The email has already been taken.";

export default function Information({ state, changeStatus }) {
  const [isSuccess, setSuccess] = useState(false);
  const [respons, setRespons] = useState(null);
  const formRef = useRef(null);
  const videoRef = useRef(null);
  // const [videoLoaded, setVideoLoaded] = useState(false);
  const isLoading = state.getState().isPageLoading;

  useEffect(() => {
    isSuccess && changeStatus("new");
    isSuccess && fbq("track", "SignUp");
    return () => {};
  }, [isSuccess]);

  useEffect(() => {
    let scr;
    if (!isLoading) {
      const [start, loop] = videoRef.current.children;
      let playeVideo = start;
      let active = false;

      scr = ScrollTrigger.create({
        // markers: true,
        trigger: videoRef.current,
        start: "top 80%",
        // endTrigger: "#otherID",
        end: "bottom top",
        onToggle: ({ isActive }) => {
          active = isActive;
          try {
            isActive ? playeVideo.play() : playeVideo.pause();
          } catch (error) {
            console.log(error);
            console.log(error.name);
          }
        },
      });

      start.addEventListener("ended", () => {
        start.classList.add("hide");
        loop.classList.remove("hide");
        loop.play();
        playeVideo = loop;
      });
    }

    return () => {
      scr?.kill();
    };
  }, [isLoading]);

  useEffect(() => {
    const status = respons?.status;
    status == "already_registered" && changeStatus("already");

    return () => {};
  }, [respons]);

  const scrollTo = () => {
    gsap.to(window, { duration: 0.5, scrollTo: formRef.current });
  };

  return (
    <>
      <section className="information">
        <div className="section_content info pd-r-md df jc-sb">
          <div className="text_content">
            <h1 className="fixel_50">Welcome to Funtico! Become a Pioneer</h1>
            <div className="description">
              <p>
                Funtico is a new, exciting gaming platform, that takes your
                favorite genres to unprecedented heights! Your skills aren't
                just a matter of pride; they unlock incredible prizes and
                exclusive perks.
              </p>
            </div>
            <CustomButton
              classname="pr as-c mr-t-46"
              def={40}
              title="Sign up now and get your Pioneer Rewards!"
              onClick={scrollTo}
            />

            <div className="imp mr-t-46">
              <p className="fixel_22">IMPORTANT NOTE:</p>
              <p className="fixel_14 mr-t-8">
                Applicable exclusively to gamers who register prior to the
                official launch of the Funtico platform. Stay tuned for the
                launch date!
              </p>
            </div>
          </div>

          {/* <div className="icon df jc-c br-8 cover">
            <div className="i df">
              <LoadImage src={scratch} alt={"Intro"} />
            </div> */}

          <div
            className={"icon df jc-c" + (!isLoading ? " loaded" : "")}
            ref={videoRef}
          >
            {!isLoading && (
              <>
                <video muted playsInline>
                  <source src={video_start} type="video/mp4" />
                </video>
                <video muted playsInline loop className="loop hide">
                  <source src={video_loop} type="video/mp4" />
                </video>
              </>
            )}
          </div>
        </div>

        <div className="section_content contact df jc-c pd-lr-md" ref={formRef}>
          <div className="text_content">
            <h2 className="fixel_36">Create your Funtico account</h2>

            <p className="mr-t-46 required fixel_14">All fields are required</p>
            <RegistrationLabels
              setSuccess={setSuccess}
              respons={setRespons}
              state={state}
              classList=" mr-t-24"
            />
          </div>
        </div>
      </section>
    </>
  );
}
