import React from "react";
import Socials from "../../../components/core/Socials";
import { NavLink } from "react-router-dom";

export default function Copyright() {
  const links = [
    {
      to: "/terms-and-conditions",
      title: "Terms & Conditions",
    },
    {
      to: "/privacy-policy",
      title: "Privacy Policy",
    },
  ];

  return (
    <>
      <footer className="copyright">
        <div className="section_content pd-lr-md df fd-c ai-c">
          <Socials />
          <nav className="mr-t-46">
            {links.map((l) => (
              <NavLink
                key={l.to}
                {...l}
                className="fixel_16_sbl"
                target="_blank"
              >
                {l.title}
              </NavLink>
            ))}
          </nav>
          <p className="mr-t-24 fixel_16_md right">©2024 Funtico, Inc.</p>
        </div>
      </footer>
    </>
  );
}
