import React from "react";
import { useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";

import store from "../../redux/state";

const DelayLink = ({ children, to, ...props }) => {
  let timeout = null;
  let navigate = useNavigate();

  useEffect(() => {
    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [timeout]);

  const handleClick = (e) => {
    if (location?.pathname === to) return;
    // if (e.defaultPrevented) return;
    e.preventDefault();
    store.setLoading(true);
    timeout = setTimeout(() => navigate(to), 300);
  };

  return to ? (
    <NavLink to={to} onClick={handleClick} {...props} end>
      {children}
    </NavLink>
  ) : (
    <div {...props}>{children}</div>
  );
};

export default DelayLink;
