import { useEffect } from "react";
import DelayLink from "../../components/core/DelayLink";

export default function NotFound({ state }) {
  useEffect(() => {
    state.setPageTitle("Page not found");

    setTimeout(() => state.setLoading(false), 100);
    return () => {};
  }, []);
  return (
    <section className="not_found">
      <div className="section_content df fd-c ai-c">
        <h1 className="title">Page not found</h1>
        <p className="fixel_22 mr-t-24">404</p>
        <DelayLink
          to={"/"}
          title="Bring me back"
          className="def pr type-40 br-16 mr-t-46"
        >
          Bring me back
        </DelayLink>
      </div>
    </section>
  );
}
