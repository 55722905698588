import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function Policy({ state }) {
  const loc = useLocation();

  useEffect(() => {
    state.setPageTitle("Privacy Policy");

    setTimeout(() => {
      state.setLoading(false);
    }, 1000);
    return () => {};
  }, [loc]);
  return (
    <section className="policy">
      <div className="section_content w-sm  pd-lr-sm">
        <h1 className="">Privacy Policy</h1>
        <div className="content mr-t-46">
          <p>
            Funtico values user privacy and is committed to protecting personal
            information. The privacy policy outlines how player data is
            collected, used, and safeguarded.
          </p>

          <h2>1. Information Collection</h2>
          <ul>
            <li>
              Funtico collects certain information during the registration
              process, including but not limited to:
              <ul>
                <li>
                  Name, email address, age, and other optional profile details.
                </li>
                <li>
                  Technical information such as IP address, device information,
                  and cookies.
                </li>
              </ul>
            </li>
          </ul>

          <h2>2. Use of Information</h2>

          <ul>
            <li>
              Collected information is used to facilitate early registration,
              provide in-game benefits, and enhance the gaming experience.
            </li>
            <li>
              Funtico may use aggregated and anonymized data for analytics,
              research, and marketing purposes.
            </li>
            <li>
              Funtico may use the information:
              <ul>
                <li>To provide and maintain the Service</li>
                <li>To notify you about changes to our Service</li>
                <li>
                  To allow you to participate in interactive features of our
                  Service
                </li>
                <li>To provide customer care and support</li>
                <li>To monitor the usage of the Service</li>
                <li>To detect, prevent and address technical issues</li>
              </ul>
            </li>
          </ul>

          <h2>3. Data Security</h2>
          <ul>
            <li>
              Funtico employs industry-standard security measures to protect
              player data from unauthorized access, alteration, disclosure, or
              destruction.
            </li>
          </ul>

          <h2>4. Third-Party Disclosure</h2>
          <ul>
            <li>
              Player information may be shared with trusted third-party service
              providers for the purpose of improving services or as required by
              law.
            </li>
          </ul>

          <h2>5. User Rights</h2>
          <ul>
            <li>
              Players have the right to access, modify, or delete their personal
              information stored by Funtico.
            </li>
            <li>
              Opt-out options for marketing communications should be available
              and easily accessible.
            </li>
          </ul>

          <h2>6. Cookie Policy</h2>
          <ul>
            <li>
              Funtico utilizes cookies and similar tracking technologies to
              enhance user experience.
            </li>
          </ul>

          <h2>7. Children’s Privacy</h2>
          <ul>
            <li>
              Our Service does not address anyone under the age of 18
              ("Children").
            </li>
            <li>
              We do not knowingly collect personally identifiable information
              from anyone under the age of 18.
            </li>
          </ul>

          <h2>8. Changes to the Privacy Policy</h2>
          <ul>
            <li>
              Funtico reserves the right to update or modify the privacy policy
              from time to time.
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
}
