
import Intro from "./intro/intro";

export default function Vision({ state }) {
  return (
    <>
      <Intro state={state} />
    </>
  );
}
