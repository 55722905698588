import React, { useEffect, useRef } from "react";
import v_1 from "../../../assets/img/pre-sign/vision/v_1.jpg";
import v_2 from "../../../assets/img/pre-sign/vision/v_2.jpg";
import v_3 from "../../../assets/img/pre-sign/vision/v_3.jpg";
import LoadImage from "../../../components/core/LazyImage";
import DelayLink from "../../../components/core/DelayLink";
import CustomButton from "../../../components/core/ButtonDefault";
import { gsap, ScrollTrigger } from "gsap/all";
gsap.registerPlugin(ScrollTrigger);
gsap.config({ nullTargetWarn: false });
export default function Intro({ state }) {
  const mobile = state.getState().isMobile;
  const visions = useRef(null);
  const version = state.getState().app_page;

  useEffect(() => {
    return () => {};
  }, []);

  useEffect(() => {
    state.setPageTitle("Vision");
    const tls = [];

    let st = setTimeout(() => {
      const clips = visions.current.querySelectorAll(".vision .icon");
      const fadeUp = document.querySelectorAll("[data-fade]");

      const fadeAnimation = (fade) => {
        const tl = gsap
          .timeline({
            scrollTrigger: {
              // markers: true,
              trigger: fade,
              start: "top bottom",
              onEnter: () => fade.classList.add("active"),
              toggleActions: "play none none reverse",
            },
          })
          .to(fade, {
            y: 0,
            opacity: 1,
            duration: 1,
          });

        tls.push(tl);
      };

      const clipAnimation = (clip, idx) => {
        const img = clip.querySelector("img");
        const isFirst = idx == 0;

        const tl = gsap
          .timeline({
            scrollTrigger: {
              // markers: true,
              trigger: clip,
              start: "top 90%",
              onEnter: () => clip.classList.add("active"),
              toggleActions: "play none none reverse",
            },
          })
          .to(clip, {
            clipPath: "polygon(0% 0%, 100% 16.5%, 100% 83.5%, 0% 100%)",
            duration: 1,
          });

        const an = gsap
          .timeline({
            scrollTrigger: {
              // markers: true,
              trigger: clip,
              start: isFirst ? "top center" : "top 90%",
              end: isFirst ? "bottom top" : "bottom center",
              scrub: true,
            },
          })
          .to(img, { yPercent: "9" });
        tls.push(an);
        tls.push(tl);
      };

      clips.forEach(clipAnimation);
      fadeUp.forEach(fadeAnimation);
    }, 300);
    setTimeout(() => state.setLoading(false), 100);

    return () => {
      tls.forEach((tl) => tl?.kill());
      clearTimeout(st);
    };
  }, []);

  const previews = [
    {
      src: v_1,
      title: "Fun At The Core",
      alt: "At Funtico, we prioritize fun above all else by crafting high-quality games that put the power in the hands of players.",
    },
    {
      src: v_2,
      title: "Smooth Play, <br /> All The Way! ",
      alt: "Technology should blend seamlessly into gaming. Funtico's straightforward gameplay is accessible to everyone to ensure optimal user experience.",
    },
    {
      src: v_3,
      title: "Play To Win <br /> Meaningful Rewards",
      alt: "Fuel your gaming fire with rewards that recognize your journey and achievements.",
    },
  ];

  return (
    <>
      <svg className="svg_hidden">
        <clipPath id="vision_clip" clipPathUnits="objectBoundingBox">
          {mobile ? (
            <path d="M1,0.138 C1,0.132,0.995,0.127,0.989,0.126 L0.014,0.002 C0.006,0.001,0,0.006,0,0.014 V0.99 C0,0.997,0.006,1,0.013,1 L0.989,0.879 C0.995,0.878,1,0.873,1,0.867 V0.138"></path>
          ) : (
            <path d="M1,0.191 C1,0.177,0.996,0.165,0.991,0.164 L0.011,0 C0.005,-0.001,0,0.011,0,0.027 V0.973 C0,0.989,0.005,1,0.011,1 L0.991,0.836 C0.996,0.835,1,0.823,1,0.809 V0.191"></path>
          )}
        </clipPath>
      </svg>

      <section className="vision_intro">
        <div className="section_content pd-lr-md df fd-c ai-c">
          <div className="text_content df fd-c ai-c">
            <div className="title df fd-c">
              <h1 className="fixel_60" data-fade>
                The Funtico VISION
              </h1>
              <div className="line"></div>
            </div>
            <h2 className="filex_42" data-fade>
              Funtico offers unparalleled gaming fueled by fun and endless
              rewards.
            </h2>
            <p className="fixel_16_md mr-t-16" data-fade>
              With Funtico’s new gaming platform, your skills don't just earn
              you bragging rights; they score you incredible prizes and
              exclusive perks. Join high-octane tournaments or face off in
              gripping one-on-one battles, and experience true ownership of
              in-game items, skins, packs and more.
            </p>
          </div>
        </div>
      </section>
      <section className="visions">
        <div className="section_content pd-lr-md df fd-c" ref={visions}>
          {previews.map((pre) => (
            <div className="vision" key={pre.alt}>
              <div className="icon df">
                <LoadImage {...pre} />
              </div>
              <div className="text_content pd-lr-lg">
                <div className="content" data-fade>
                  <h3
                    className="fixel_50"
                    dangerouslySetInnerHTML={{ __html: pre.title }}
                  ></h3>
                  <p className="fixel_16_md mr-t-10">{pre.alt}</p>
                </div>
              </div>
            </div>
          ))}
          <DelayLink
            to={version == "platform" ? "/pre" : "/"}
            title="Go Back To The Registration Page"
            className="vision_link as-c"
            data-fade
          >
            <CustomButton
              classname="pr"
              def={40}
              title="Go Back To The Registration Page"
            ></CustomButton>
          </DelayLink>
        </div>
      </section>
    </>
  );
}
