import React, { lazy } from "react";
import { BrowserRouter } from "react-router-dom";
import ReactDOM from "react-dom/client";
import store from "./redux/state";
import SignApp from "./SignApp";

const root = ReactDOM.createRoot(document.getElementById("root"));




let rerenderEntireTree = (state) => {
  root.render(
    <BrowserRouter basename={state.basePath}>
      <SignApp state={store} />
    </BrowserRouter>
  );
};

rerenderEntireTree(store.getState());

store.subscribe(rerenderEntireTree);
