import React, { useEffect, useState } from "react";
import Ico from "../../../assets/img/icons/checkbox.svg";
import { NavLink } from "react-router-dom";

export default function Checkbox({
  checked,
  title,
  handleChange,
  value,
  children,
  def,
  underline,
  ...props
}) {
  const agree = () => (
    <span className="txt">
      I agree to the{" "}
      <NavLink
        to="/terms-and-conditions"
        title="Terms and Conditions"
        target="_blank"
      >
        Terms & Conditions
      </NavLink>{" "}
      and{" "}
      <NavLink to="/privacy-policy" title="Privacy Policy" target="_blank">
        Privacy Policy
      </NavLink>
    </span>
  );

  useEffect(() => {
    if (def) {
      const el = { target: { checked: true, ...props } };
      handleChange(el);
    }

    return () => {};
  }, []);

  return (
    <>
    {underline && <div className="underline"></div>}
      <label className={`label checkbox ${value ? "active" : ""}`}>
        <div className="row">
          <input checked={value} onClick={handleChange} readOnly {...props} />
          <div className="ico df br-8">
            <Ico />
          </div>
          {children ??
            (props.name == "agree" ? (
              agree()
            ) : (
              <span
                className="txt"
                dangerouslySetInnerHTML={{ __html: title }}
              ></span>
            ))}
        </div>
      </label>
    </>
  );
}
