import React, { createContext, useState } from "react";
import { useEffect } from "react";

const AuthContext = createContext({});

const initialState = {
  name: "",
  email: "",
  token: null,
  isLoggedIn: false,
};

export function AuthProvider({ children }) {
  const [auth, setAuth] = useState(() => {
    // Load auth state from localStorage if it exists
    const authData = localStorage.getItem("auth");

    return JSON.parse(authData) || initialState;
  });

  useEffect(() => {
    localStorage.setItem("auth", JSON.stringify({ ...auth }));
    return () => {};
  }, [auth]);

  return (
    <AuthContext.Provider value={{ auth, setAuth }}>
      {children}
    </AuthContext.Provider>
  );
}

export default AuthContext;
