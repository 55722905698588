import { useEffect, useRef, useState } from "react";
import gsap, { ScrollToPlugin } from "gsap/all";

gsap.registerPlugin(ScrollToPlugin);

import Information from "./information/information";
import Rewards from "./rewards/rewards";
import Join from "./join/join";
import Trailers from "./trailers/trailers";
import Intro from "./intro/intro";
import { useSearchParams } from "react-router-dom";
import useAxiosREST from "../../utils/hooks/useAxiosAPI";
import Message from "./message/message";

export default function Sign({ state, version }) {
  const [mode, setUserStatus] = useState("def");
  const [searchParams, setSearchParams] = useSearchParams();
  const { fetchAPI } = useAxiosREST();

  useEffect(() => {
    const hash = searchParams.get("hash");
    const id = searchParams.get("id");
    state.setPageTitle("Pioneers");
    const delay = window.isLoaded ? 10 : 1500;

    const getVerifyStatus = async (d) => {

      const data = await fetchAPI.post("auth/verify", d);

      const cleare = () => {
        searchParams.delete("hash");
        searchParams.delete("id");
        setSearchParams(searchParams);
        state.setLoading(false);
      };

      if (data.status == "already_registered") {
        setUserStatus("already");
        cleare();
        return;
      }

      if (data?.data?.id && !data?.message) {
        cleare();
        setUserStatus("confirmed");
      } else setUserStatus("error");
    };

    if (hash && id) {
      const data = new FormData();
      data.append("id", id);
      data.append("hash", hash);
      getVerifyStatus(data);
    } else setTimeout(() => state.setLoading(false), delay);

    window.isLoaded = true;
    return () => {};
  }, []);

  const changeStatus = (status) => {
    const main = document.querySelector("main");
    gsap.to([], {
      direction: 0.5,
      onStart: () => main.classList.add("hidden"),
      onComplete: () => {
        setUserStatus(status);
        setTimeout(() => main.classList.remove("hidden"), 100);
      },
    });
    gsap.to(window, { duration: 0.5, scrollTo: { x: 0, y: 0 } });
  };

  return (
    <>
      <Intro state={state} />
      {mode == "def" ? (
        <>
          <Information state={state} changeStatus={changeStatus} />
          <Rewards />
          <Trailers state={state} />
          <Join />
        </>
      ) : (
        <Message mode={mode} changeStatus={changeStatus} />
      )}
    </>
  );
}
