import React, { useEffect, useRef, useState } from "react";
import Arrow from "../../../assets/img/icons/arrow_right.svg";

export default function Select({
  list,
  label,
  handleChange,
  error,
  name,
  search,
  def,
  tip,
  ...props
}) {
  const [title, setValue] = useState(def ?? "");
  const [isOpen, setIsOpen] = useState(false);
  const [contentList, setContentList] = useState(Object.entries(list));
  useEffect(() => {
    isOpen && document.body.addEventListener("click", onBodyClick);
    return () => document.body.removeEventListener("click", onBodyClick);
  }, [isOpen, props.value]);

  const onClick = ({ target }) => {
    setIsOpen(!isOpen);
  };
  const onBodyClick = ({ target }) => {
    if (!target.closest(".select")) {
      setIsOpen(false);
      !props.value && setValue("");
      !props.value && setContentList(Object.entries(list));
    }
  };
  const handleSelect = (e) => {
    setValue(e.target.textContent);
    handleChange(e);
    setIsOpen(false);
    setContentList(Object.entries(list));
  };

  const lower = (txt) => {
    return txt.toLocaleLowerCase();
  };

  const handleSearch = (e) => {
    const nul = { target: { value: "", name: name } };
    const val = e.target.value;
    const filtered = Object.entries(list)
      .filter((l) => lower(l[1]).includes(lower(val)))
      .map((a) => [
        a[0],
        a[1].replace(new RegExp(val, "gi"), "<span>$&</span>"),
      ]);

    handleChange(nul);
    setIsOpen(true);
    setValue(val);
    setContentList(filtered);
  };

  return (
    <label
      className={`label select${isOpen ? " open" : ""} ${error ? "error" : ""}`}
    >
      {tip && (
        <div className="top_tip df ai-c jc-st">
          <p className="fixel_14">{tip.title}</p>

          {tip?.tip && (
            <>
              <div
                className="icon"
                onClick={({ target }) =>
                  target.closest(".icon").classList.toggle("active")
                }
              ></div>
              <p className="info fixel_14">{tip?.tip}</p>
            </>
          )}
        </div>
      )}
      {label || error ? (
        <div
          className="name fixel_14"
          dangerouslySetInnerHTML={{ __html: error || label }}
        ></div>
      ) : null}
      <div className="selected" data-open onClick={onClick}>
        <input
          placeholder={props.placeholder}
          readOnly={!search}
          value={title}
          onChange={(e) => search && handleSearch(e)}
          className={search ? "search" : ""}
          id={"hidden_" + name}
          required={props.required || false}
        />
        <input readOnly {...props} className="hidden" name={name} />
        <div className="border"></div>
        <div className="icon df">
          <Arrow />
        </div>
      </div>
      <div className={`list`}>
        <div className="list_content df fd-c br-14">
          {contentList.length ? (
            contentList.map((l, i) => (
              <button
                key={i}
                className={`l br-10 ${props.value == l[0] ? "sel" : ""}`}
                onClick={handleSelect}
                value={l[0]}
                name={name}
                type="button"
                dangerouslySetInnerHTML={{ __html: l[1] }}
              ></button>
            ))
          ) : (
            <button className={`l br-10 sel`} type="button">
              Nothing to found
            </button>
          )}
        </div>
      </div>
    </label>
  );
}
