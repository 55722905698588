import React from "react";
import gsap, { ScrollToPlugin } from "gsap/all";
import CustomButton from "../../../components/core/ButtonDefault";
gsap.registerPlugin(ScrollToPlugin);

export default function Join() {
  return (
    <>
      <section className="pre-join">
        <div className="section_content pd-lr-md df">
          <div className="content df ai-en jc-c br-16">
            {/* <h2 className="fixel_50">Become A Summoner!</h2> */}
            <div className="bg"></div>
            <a
              href="https://about.funtico.com/"
              target="_blank"
              className="link"
              title="Claim TICO Now!"
            >
              <CustomButton
                classname="pr"
                def={40}
                title="Claim TICO Now!"
              ></CustomButton>
            </a>
          </div>
        </div>
      </section>
    </>
  );
}
