import React, { useContext, useState } from "react";
import axios from "axios";
import AuthContext from "../../context/AuthProvider";

const useAxiosREST = () => {
  const [isFetching, setFetching] = useState(false);
  const [error, setError] = useState(null);
  const { auth, setAuth } = useContext(AuthContext);

  const URL =
    process.env.NODE_ENV == "development"
      ? "api/v1/"
      : process.env.REACT_APP_API_BASE_URL;

  const bearer = auth?.token ? { Authorization: `Bearer ${auth.token}` } : {};
  // Create axios instance
  const client = axios.create({
    // baseURL: "api/v1/",
    baseURL: URL,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      ...bearer,
    },
  });
  client.delete;

  const fetchAPI = {
    get: async (end, params = null) => {
      setFetching(true);
      try {
        const res = await client.get(end, { params });
        setFetching(false);
        return [res.data, null];
      } catch (error) {
        setFetching(false);
        setError(error);
        // console.error("Error response:", error.response);
        return [error.response?.data, error];
      }
    },
    post: async (end, data = null) => {
      setFetching(true);
      try {
        const res = await client.post(end, data);
        setFetching(false);
        return res.data;
      } catch (error) {
        setFetching(false);
        setError(error);
        return error.response?.data;
      }
    },
    delete: async (end, data = null) => {
      setFetching(true);
      try {
        const res = await client.delete(end, { data: data });
        setFetching(false);
        return res.data;
      } catch (error) {
        setFetching(false);
        setError(error);
        return error.response?.data;
      }
    },
    patch: async (end, data = null) => {
      setFetching(true);
      try {
        const res = await client.patch(end, data);
        // console.log(res);
        setFetching(false);
        return res.data;
      } catch (error) {
        setFetching(false);
        setError(error);
        return error.response?.data;
      }
    },
  };

  return { isFetching, error, fetchAPI };
};

export default useAxiosREST;
