import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import Preloader from "./components/layout/Preloader";
import Sign from "./views/sign-up/Sign";
import { ScrollBar } from "./utils/ScrollBarFactory";
import Vision from "./views/vision/Vision";
import { useLocation } from "react-router-dom";
import PreSign from "./layouts/PreSign";
import NotFound from "./views/404/404";
import Policy from "./views/policy/Policy";
import Terms from "./views/policy/Terms";
import { useWindowSize } from "./utils/hooks/use-EventListener";
import "./assets/scss/index-sign.scss";

// import TagManager from "react-gtm-module";

// const tagManagerArgs = {
//   gtmId: "G-QS7FPMTJQ6",
//   // gtmId: "GTM-5BJRDF4",
//   dataLayerName: "dataLayer",
// };
// TagManager.initialize(tagManagerArgs);

export default function SignApp({ state }) {
  const location = useLocation();
  const width = useWindowSize();

  console.log("first")

  useEffect(() => {
    state.setDeviceType(width);
    return () => {};
  }, [width]);

  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {};
  }, [location]);

  useEffect(() => {
    let scroll = ScrollBar.create();
    return () => {
      scroll.kill();
    };
  }, []);

  return (
    <>
      {<Preloader state={state} />}
      <>
        <Routes>
          <Route path="/" element={<PreSign state={state} />}>
            <Route path="/" element={<Sign state={state} />} />
            <Route path="/vision" element={<Vision state={state} />} />
            <Route
              path="/terms-and-conditions"
              element={<Terms state={state} />}
            />
            <Route path="/privacy-policy" element={<Policy state={state} />} />
            <Route path="*" element={<NotFound state={state} />} />
          </Route>
        </Routes>
      </>
    </>
  );
}
