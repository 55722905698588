import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import Copyright from "../views/sign-up/copyright/copyright";

export default function PreSign({ state }) {
  const { documentTitle, pageTitle } = state.getState();
  const [pageName, setPageName] = useState("");

  useEffect(() => {
    const t = pageTitle;
    document.title = `${documentTitle} ${t ? " " + t : " "}`;
    setPageName(t?.toLowerCase().split(" - ")[0].split(" ").join("_"));
    return () => {};
  }, [pageTitle]);

  return (
    <>
      <main className={"page_" + pageName}>
        <Outlet />
      </main>
      <Copyright />
    </>
  );
}
