import React, { useEffect, useState } from "react";
import CustomButton from "../../../components/core/ButtonDefault";

export default function Message({ mode, changeStatus }) {
  const content = {
    new: {
      title: "You're Only One Step Away!",
      subtitle: "Check your inbox for our latest email!",
      description: `Congratulations on starting your gaming journey with Funtico. <br /> To validate your account, please verify your email address${
        window.regg_mail
          ? `:<br /> <span class="mr-t-12">${window.regg_mail}</span>`
          : "."
      }`,
    },
    confirmed: {
      title: "You're In - Welcome to Funtico!",
      subtitle: "Welcome aboard, gamer!",
      description: `You've just made an epic move by joining the Funtico family. Trust us, you  won't want to miss the jaw-dropping action, killer prizes, and exclusive perks lined up for you. <br /> <br /> Stay ahead of the pack by following us on social media. We drop  game-changing updates, announce new tournaments, and even host giveaways  you won't want to miss. `,
    },
    already: {
      title: "Thanks for being part of the Funtico family!",
      subtitle: "Ready to climb the leaderboards?",
      description: `You already have a Funtico account, meaning you're already to steps ahead of the competition! <br /><br />To get an even bigger lead, follow Funtico on social media. We drop game-changing updates, announce new tournaments, and host giveaways you won't want to miss.`,
    },
    error: {
      title: "Something Went Wrong...",
      description:
        "The validation URL link used is not valid. <br /> Please check it again!",
    },
  };

  const { title, subtitle, description } = content[mode];

  const goBack = () => {
    changeStatus("def");
  };
  return (
    <>
      <section className={"message"}>
        <div className="section_content pd-lr-md df jc-c">
          <div className="text_content df fd-c ai-c">
            <h1 className="fixel_50">{title}</h1>
            <p
              className="fixel_16_md mr-t-24 df fd-c"
              dangerouslySetInnerHTML={{ __html: description }}
            ></p>

            {subtitle && (
              <p className={"fixel_26 mr-t-46 " + mode}>{subtitle}</p>
            )}
            <CustomButton
              classname="pr as-c mr-t-46"
              def={40}
              title="Back to Main Page"
              onClick={goBack}
            />
          </div>
        </div>
      </section>
    </>
  );
}
