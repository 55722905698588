import React from "react";
import Discord from "../../assets/img/socials/discord.svg";
import Facebook from "../../assets/img/socials/facebook.svg";
import Medium from "../../assets/img/socials/medium.svg";
import Telegram from "../../assets/img/socials/telegram.svg";
import Twitter from "../../assets/img/socials/twitter.svg";
import Youtube from "../../assets/img/socials/youtube.svg";
import Tiktok from "../../assets/img/socials/tiktok.svg";
import Instagram from "../../assets/img/socials/instagram.svg";
import Threads from "../../assets/img/socials/threads.svg";
import Twitch from "../../assets/img/socials/Twitch.svg";
import Kicks from "../../assets/img/socials/Kicks.svg";
import Linkedin from "../../assets/img/socials/Linkedin.svg";

const soc = [
  {S: Twitter, link: "https://twitter.com/Funtico_com",},
  {S: Discord, link: "https://discord.gg/kN4kAAHvQd",},
  {S: Facebook, link: "https://www.facebook.com/profile.php?id=61551632833409",},
  {S: Tiktok, link: "https://www.tiktok.com/@funtico.com?_t=8jdJxCTyQrp&_r=1",},
  {S: Instagram, link: "https://www.instagram.com/funtico_com/",},
  {S: Linkedin, link: "https://www.linkedin.com/company/funtico",},
  {S: Medium, link: "https://medium.com/@funtico",},
  {S: Youtube, link: "https://www.youtube.com/channel/UCKNSppHiWRtKlqxyvAntNwQ",},
  // {S: Telegram, link: "https://web.telegram.org/",},
  // {S: Twitch, link: "https://www.twitch.tv/funtico_official",},
  // {S: Kicks, link: "https://kick.com/funtico",},

];
export default function Socials({ clas }) {
  return (
    <div className={`socials ${clas ?? ""} df fw`}>
      {soc.map((social, i) => (
        <a key={i} href={social.link} target="_blank" className="network df">
          <social.S />
        </a>
      ))}
    </div>
  );
}
