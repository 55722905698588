import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function Terms({ title, state }) {
  const loc = useLocation();

  useEffect(() => {
    state.setPageTitle("Terms and Conditions");

    setTimeout(() => {
      state.setLoading(false);
    }, 1000);
    return () => {};
  }, [loc]);
  return (
    <section className="policy">
      <div className="section_content w-sm  pd-lr-sm">
        <h1 className="">Terms and Conditions</h1>
        <div className="content mr-t-46">
          <h2>1. Introduction</h2>
          <p>
            Welcome to Funtico's early registration page for gamers. By
            registering early and availing yourself of in-game benefits, you
            agree to abide by these terms and conditions.
          </p>

          <h2>2. Early Registration Benefits</h2>

          <ul>
            <li>
              Funtico offers in-game benefits to players who register early.
              These benefits may include exclusive items, in game items,
              bonuses, or access to limited features, as outlined on the
              registration page or sent to you via email.
            </li>
            <li>
              The nature and availability of these benefits may be subject to
              change at Funtico's discretion.
            </li>
          </ul>

          <h2>3. Web3 and Cryptocurrency Use</h2>
          <ul>
            <li>
              Funtico operates within a Web3 environment, where the introduction
              of its native token, "Tico," may be a possibility in the future.
            </li>
            <li>
              Players acknowledge and understand that within the Funtico
              ecosystem, some games or in-game items might be purchasable using
              cryptocurrencies or the native token.
            </li>
          </ul>

          <h2>4. Eligibility</h2>
          <ul>
            <li>
              Players must be at least 18 years old to register early and claim
              in-game benefits.
            </li>
            <li>Registration is limited to residents of eligible countries.</li>
            <li>
              Funtico reserves the right to verify the participant's age,
              residency, and eligibility criteria and may reject or revoke
              registration if found to be ineligible or in violation of these
              terms.
            </li>
          </ul>

          <h2>5. Disclaimer of Warranties and Limitation of Liability</h2>
          <ul>
            <li>
              We provide the Service ‘AS IS’, with all faults and as available.
              We and our licensors make no express warranties or guarantees
              about the Service.
            </li>
            <li>
              Funtico and its licensors shall not be liable for any direct,
              indirect, incidental, consequential, or punitive damages arising
              from the early registration or use of in-game benefits or games
              themselves.
            </li>
          </ul>

          <h2>6. Registration Process</h2>
          <ul>
            <li>
              Players are required to provide accurate and complete information
              during the registration process.
            </li>
            <li>
              Funtico reserves the right to verify the information provided and
              reject or revoke registration if found to be inaccurate,
              incomplete, or in violation of these terms.
            </li>
          </ul>
          <h2>7. In-Game Benefits</h2>
          <ul>
            <li>
              In-game benefits obtained through early registration are
              non-transferable and have no monetary value outside of the game.
            </li>
            <li>
              Funtico reserves the right to modify or revoke in-game benefits at
              any time without prior notice.
            </li>
          </ul>
          <h2>8. User Conduct</h2>
          <ul>
            <li>
              Players agree to abide by Funtico's community guidelines and terms
              of service while using the game and its features.
            </li>
            <li>
              Any misuse, abuse, or violation of these terms may result in the
              forfeiture of in-game benefits and potential suspension or
              termination of the player's account.
            </li>
          </ul>
          <h2>9. Governing Law and Dispute Resolution</h2>
          <ul>
            <li>
              These terms and conditions shall be governed by and construed in
              accordance with the laws of Panama.
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
}
