import React from "react";
// import img1 from "../../../assets/img/back/sign.jpg";
import img1 from "../../../assets/img/back/sign.webp";
// import img1_mob from "../../../assets/img/back/sign_mob.jpg";

const links = [
  {
    title: "Learn About Funtico",
    href: "https://about.funtico.com/",
    target: "_blank",
  },
  {
    title: "Check EV2",
    href: "https://ev2.funtico.com/",
    target: "_blank",
  },
];

export default function Intro({ state }) {
  const mobile = state.getState().isMobile;

  const image = img1;

  return (
    <>
      <section className="intro">
        <div className="section_content df fd-c ai-c pd-lr-md">
          <div className="img df">
            <img src={image} alt="Welcome" />
          </div>
          <div className="navs df jc-c mr-t-46 fw">
            {links.map((l, i) => (
              <a key={i} className="link def stroke type-40 br-16" {...l}>
                <span className="txt">{l.title}</span>
              </a>
            ))}
          </div>
        </div>
      </section>
    </>
  );
}
